import React, { lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
import ScrollToTop from "./ScrollToTop";
import "./App.css";
import PrivateRoute from "./PrivateRoute";
import { Helmet } from "react-helmet";
const Book = lazy(() => import("./pages/Book/Book"));
const Result = lazy(() => import("./pages/Result/Result"));
const MyBooking = lazy(() => import("./pages/MyBooking/MyBooking"));
const BookingNotFound = lazy(() =>
  import("./components/BookingNotFound/BookingNotFound")
);
const ShowRoom = lazy(() => import("./pages/ShowSelection/ShowRoom"));
const ScheduleRide = lazy(() => import("./pages/ScheduleRide/Schedule"));
const LoadingPage = lazy(() => import("./components/LoadingPage/LoadingPage"));
const SuccessfullLoading = lazy(() =>
  import("./components/SuccessfulLoading/SuccessfullLoading")
);
const Blogs = lazy(() => import("./pages/Blogs/Blogs"));
const Article = lazy(() => import("./pages/Blogs/Article"));
const ModifyRides = lazy(() => import("./pages/ModifyRides/ModifyRide"));
const Press = lazy(() => import("./pages/Press/Press"));
const Alert = lazy(() => import("./components/Alert/Alert"));
const Specification = lazy(() =>
  import("./pages/Home/FullSpecification/FullSpecificationComp")
);
const SavingCalculator = lazy(() =>
  import("./pages/SavingCalculator/SavingCalculator")
);
const SavingCalResult = lazy(() =>
  import("./pages/SavingCalResult/SavingCalResult")
);
const ObenEvNotAvailable = lazy(() =>
  import("./components/ObenEvNotAvailable/ObenEvNotAvailable")
);
const Faq = lazy(() => import("./pages/FAQ/Faq"));
const UIGoa = lazy(() => import("./components/SavingCalResult/Goa/UIGoa"));
const Careers = lazy(() => import("./pages/Careers/Careers"));
const ContactUs = lazy(() => import("./pages/ContactUs/ContactUs"));
const TermsAndCondition = lazy(() =>
  import("./pages/TermsAndCondition/TermsAndCondition")
);
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy/PrivacyPolicy"));
const JobDescription = lazy(() =>
  import("./pages/JobDescription/JobDescription")
);
const AboutUs = lazy(() => import("./pages/AboutUs/AboutUs"));
const CompanyBoardPeople = lazy(() =>
  import("./components/CompanyBoardPeople/CompanyBoardPeople")
);
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));
const BatteryImage = lazy(() => import("./pages/Home/Battery/BatteryImage"));
const Performance = lazy(() =>
  import("./pages/HomeSection/Performance/Performance")
);
const PerformanceAnimaScroll = lazy(() =>
  import(
    "./pages/HomeSection/Performance/PerformanceAnimaScroll/PerformanceAnimaScroll"
  )
);
const Charging = lazy(() => import("./pages/HomeSection/Charging/Charging"));
const Connectivity = lazy(() =>
  import("./pages/Home/Connectivity/Connectivity")
);
const DesignExpand = lazy(() => import("./pages/Home/Design/DesignExpand"));
const SafetyComp = lazy(() =>
  import("./pages/Home/Safety/SafetyComp/SafetyNEwComp")
);
const WarrentyComp = lazy(() =>
  import("./pages/Home/Warrenty/WarrentyComp.js/WarrentyComp")
);
const FeedbackResult = lazy(() =>
  import("./components/Feedback/FeedbackResult/FeedbackResult")
);
const Cancellation = lazy(() => import("./pages/Cancellation/Cancellation"));

const HomeComp = lazy(() => import("./pages/Home/Home"));
const WService = lazy(() => import("./Web/Pages/WService/WService"));
const WDesign = lazy(() => import("./Web/Pages/Home/Design/WDesign"));
const WLandingService = lazy(() =>
  import("./Web/Pages/Home/ServiceandWarrenty/WLandingService")
);
const WConnectivity = lazy(() =>
  import("./Web/Pages/Home/Connectivity/WConnectivity")
);
const WCareers = lazy(() => import("./Web/Pages/Careers/WCareers"));
const WBlogs = lazy(() => import("./Web/Pages/Home/Home/Blogs/WBlogs"));
const WPress = lazy(() => import("./Web/Pages/Home/Home/Press/WPress"));
const WFaq = lazy(() => import("./Web/Pages/Faq/WFaq"));
const WContactUs = lazy(() => import("./Web/Pages/ContactUs/WContactUs"));
const WPrivacyPolicy = lazy(() =>
  import("./Web/Pages/PrivacyPolicy/WPrivacyPolicy")
);
const WTermsConditions = lazy(() =>
  import("./Web/Pages/TermsConditions/WTermsConditions")
);
const WCancellation = lazy(() =>
  import("./Web/Pages/Cancellation&RefundPolicy/WCancellation")
);
const WBlogDetail = lazy(() =>
  import("./Web/Pages/Home/Home/Blogs/WBlogDetail")
);
const WBooking = lazy(() => import("./Web/Pages/Booking/WBooking"));
const WShowroom = lazy(() => import("./Web/Pages/ShowroomWeb/wShowroom"));
const WSchedule = lazy(() => import("./Web/Pages/ScheduleWeb/wSchedule"));
const Wresult = lazy(() => import("./Web/Pages/ResultWeb/wresult"));
const WSavingCalWeb = lazy(() =>
  import("./Web/Pages/SavingCalcWeb/savingCalcWeb")
);
const WSavingCalResult = lazy(() =>
  import("./Web/Pages/SavingCalResultweb/SavinfCalResultWeb")
);
const WBattery = lazy(() => import("./Web/Pages/Home/Battery/WBattery"));
const WPerformanceExpanded = lazy(() =>
  import("./Web/Pages/Home/Performance/WPerformanceExpanded")
);
const WCharging = lazy(() => import("./Web/Pages/Home/Charging/WCharging"));
const WmyBooking = lazy(() => import("./Web/Pages/MyBooking/WMyBooking"));
const WPricing = lazy(() => import("./Web/Pages/Home/wPricing/WPricing"));
const WReliable = lazy(() => import("./Web/Pages/Home/Reliability/WReliable"));
const WRelExpanded = lazy(() =>
  import("./Web/Pages/Home/Reliability/Reliability-expanded/WRelExpanded")
);
const WConnectivityDetail = lazy(() =>
  import("./Web/Pages/Home/Connectivity/WConnectivityDetail")
);
const WPressDetail = lazy(() =>
  import("./Web/Pages/Home/Home/Press/WPressDetail")
);
const LandingPage = lazy(() => import("./Web/Pages/LandingPage/LandingPage"));
const WSafexpanded = lazy(() =>
  import("./Web/Pages/Home/Safety/Safety-expanded/WSafexpanded")
);
const WServiceExpanded = lazy(() =>
  import(
    "./Web/Pages/Home/ServiceandWarrenty/ServiceandWarrentyexpanded/WServiceExpanded"
  )
);
const WBatteryExpendNew = lazy(() =>
  import("./Web/Pages/Home/BatteryExpend/wBatteryExpend")
);
const WDesignDetail = lazy(() =>
  import("./Web/Pages/Home/Design/WDesignDetail")
);
const WAboutUs = lazy(() => import("./Web/Pages/AboutUs/WAboutUs"));
const ResultBooked = lazy(() => import("./pages/Result/ResultBooked"));
const ModifyRegisteredUser = lazy(() =>
  import("./pages/ModifyRides/ModifyRegisterdRide")
);
const WRegesterdResult = lazy(() =>
  import("./Web/Pages/ResultWeb/wRegesterdResult")
);
const WJobDescription = lazy(() =>
  import("./Web/Pages/Careers/CareerExpanded/WJobDescription")
);
const FormSumbit = lazy(() =>
  import("./Web/Pages/Careers/CareerExpanded/FormSumbit")
);
const ReliabilityNewcomp = lazy(() =>
  import("./pages/HomeSection/Reliablity/ReliabilityNewcomp")
);
const WChargingExpand = lazy(() =>
  import("./Web/Pages/Home/Battery/WChargingExpand.js")
);
function App() {
  localStorage.setItem("scrollIndex", "Home");
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org/",
            "@type": "Product",
            name: "Rorr",
            image: [
              "https://front.obenev.com/static/media/hamburger_desktop.3788069b174c117ca6d7.jpg",
            ],
            description:
              "Best electric performance bike in india,3s (0-40) acceleration, 100km/hr top speed, 200km range (IDC), 2hrs charge time battery",
            aggregateRating: {
              "@type": "AggregateRating",
              ratingValue: "4.9",
              reviewCount: "50",
              bestRating: "5",
              worstRating: "1",
            },
            offers: {
              "@type": "Offer",
              priceCurrency: "INR",
              price: "99999.00",
            },
          })}
        </script>
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Organization",
            name: "Oben Electric",
            url: "http://obenelectric.com",
            sameAs: [
              "https://www.facebook.com/ObenElectric",
              "https://www.instagram.com/ObenElectric",
              "https://www.linkedin.com/company/oben-ev",
              "https://www.youtube.com/ObenElectric",
            ],
          })}
        </script>
      </Helmet>
      <React.Suspense fallback={<span>Loading...</span>}>
        <BrowserRouter>
          <ScrollToTop />
          <BrowserView>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/ChargingExpand" element={<WChargingExpand />} />
              <Route path="/warrenty" element={<WService />} />
              <Route path="/about-us" element={<WAboutUs />} />

              <Route path="/design" element={<WDesign />} />
              <Route path="/service" element={<WLandingService />} />
              <Route path="/connectivity" element={<WConnectivity />} />
              <Route path="/reliable" element={<WReliable />} />
              <Route path="/reliabilityexpanded" element={<WRelExpanded />} />
              <Route
                path="/performanceexpanded"
                element={<WPerformanceExpanded />}
              />
              <Route path="/safetyexpanded" element={<WSafexpanded />} />
              <Route path="/serviceexpanded" element={<WServiceExpanded />} />
              <Route
                path="/connectivityexpanded"
                element={<WConnectivityDetail />}
              />
              <Route path="/careers" element={<WCareers />} />
              <Route path="/careers2" element={<WJobDescription />} />
              <Route path="/careers3" element={<FormSumbit />} />

              <Route path="/blog" element={<WBlogs />} />
              <Route path="/charging" element={<WCharging />} />
              <Route path="/connectivity" element={<WConnectivity />} />
              <Route path="/press" element={<WPress />} />
              <Route path="/faq" element={<WFaq />} />
              <Route path="/contact-us" element={<WContactUs />} />
              <Route path="/privacy-policy" element={<WPrivacyPolicy />} />
              <Route
                path="/terms-and-condition"
                element={<WTermsConditions />}
              />
              <Route path="/cancel" element={<WCancellation />} />
              <Route path="/blog-detail/:blog_id" element={<WBlogDetail />} />
              <Route
                path="/press-detail/:press_id"
                element={<WPressDetail />}
              />
              <Route path="/design-detail" element={<WDesignDetail />} />

              <Route path="/Booking" element={<WBooking />} />
              <Route
                path="/WShowroom"
                element={
                  <PrivateRoute>
                    <WShowroom />
                  </PrivateRoute>
                }
              />
              <Route
                path="/WSchedule"
                element={
                  <PrivateRoute>
                    <WSchedule />
                  </PrivateRoute>
                }
              />
              <Route
                path="/WResult"
                element={
                  <PrivateRoute>
                    <Wresult />
                  </PrivateRoute>
                }
              />
              <Route path="/savingCalc" element={<WSavingCalWeb />} />
              <Route path="/savingRes" element={<WSavingCalResult />} />
              <Route path="/battery" element={<WBattery />} />
              <Route path="/WebmyBooking" element={<WmyBooking />} />
              <Route path="/pricing" element={<WPricing />} />
              <Route path="/batteryExpand" element={<WBatteryExpendNew />} />
              <Route
                path="/bookedDataWeb"
                element={
                  <PrivateRoute>
                    <WRegesterdResult />
                  </PrivateRoute>
                }
              />
            </Routes>
          </BrowserView>

          <MobileView>
            <Routes>
              <Route path="/" element={<HomeComp />} />
              <Route path="/book" element={<Book />} />
              <Route
                path="/result"
                element={
                  <PrivateRoute>
                    <Result />
                  </PrivateRoute>
                }
              />
              <Route path="/mybooking" element={<MyBooking />} />
              <Route
                path="/showroomselection"
                element={
                  <PrivateRoute>
                    <ShowRoom />
                  </PrivateRoute>
                }
              />
              <Route
                path="/scheduleride"
                element={
                  <PrivateRoute>
                    <ScheduleRide />
                  </PrivateRoute>
                }
              />
              <Route path="/loadinginprogress" element={<LoadingPage />} />
              <Route path="/loadingcomplete" element={<SuccessfullLoading />} />

              <Route
                path="/test-booking"
                element={
                  <PrivateRoute>
                    <ResultBooked />
                  </PrivateRoute>
                }
              />
              <Route
                path="/modifyRegistered"
                element={
                  <PrivateRoute>
                    <ModifyRegisteredUser />
                  </PrivateRoute>
                }
              />
              <Route path="/blogs" element={<Blogs />} />
              <Route path="/article" element={<Article />} />

              <Route
                path="/modifyrides"
                element={
                  <PrivateRoute>
                    <ModifyRides />
                  </PrivateRoute>
                }
              />
              <Route path="/press" element={<Press />} />
              <Route path="/alert" element={<Alert />} />
              <Route path="/speciafication" element={<Specification />} />
              <Route path="/careers" element={<Careers />} />
              <Route path="/savingcalculator" element={<SavingCalculator />} />
              <Route path="/scr" element={<SavingCalResult />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route
                path="/terms-and-condition"
                element={<TermsAndCondition />}
              />
              <Route path="/job-description" element={<JobDescription />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/page-not-found" element={<PageNotFound />} />
              <Route path="/performance" element={<Performance />} />
              <Route path="/check" element={<PerformanceAnimaScroll />} />
              <Route path="/charging" element={<Charging />} />
              <Route path="/connectivity" element={<Connectivity />} />
              <Route path="/design" element={<DesignExpand />} />
              <Route path="/cancel" element={<Cancellation />} />
              <Route
                path="/reliabilityDetails"
                element={<ReliabilityNewcomp />}
              />
              <Route path="/bnf" element={<BookingNotFound />} />
              <Route path="/oevnf" element={<ObenEvNotAvailable />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/goa" element={<UIGoa />} />
              <Route path="/alert" element={<Alert />} />
              <Route
                path="/companyboardpeople"
                element={<CompanyBoardPeople />}
              />
              <Route path="/BatteryImg" element={<BatteryImage />} />
              <Route path="/SafetyComp" element={<SafetyComp />} />
              <Route path="/warrenty" element={<WarrentyComp />} />
              <Route path="/fr" element={<FeedbackResult />} />
              <Route path="/au-working" element={<AboutUs />} />
            </Routes>
          </MobileView>
        </BrowserRouter>
      </React.Suspense>
    </>
  );
}

export default App;
